import "./Title.css";
import MainPhoto from "../../images/mainPhoto.png";


export default function Title() {
    return (
        <main className="main_title">
            <div className="title">
                <h1>
                КОНЦЕРТНЫЙ ТУР AKMAL'
                    </h1>
                    <a href="#concerts" className="button_title">
                    СМОТРЕТЬ БИЛЕТЫ
                    </a>
            </div>
           <img src={MainPhoto} alt="main photo"/>
        </main>
    );
}